import {
  QUEST_REWARD_NAMES,
  QUEST_STATUS,
  QuestDetailsType,
  QuestsFiltersType,
  ResourceType,
  humanize,
  nFormatter,
} from '@xborglabs/ui-shared';
import { useResource } from '@xborglabs/ui-shared/dist/client';
import { formatDistance, isValid } from 'date-fns';
import { m } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ReactNode, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Tippy } from '@/lib/tippy';
import { InventoryItemReward, QuestStatus } from '@/modules/quests/components';
import { Icon as AppIcon } from '@/modules/shared/components/Atom/Icon';
import { Parser } from '@/modules/shared/components/Organism/RichText/Parser/Parser';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

import { getQuestIcon, useQuestTitles } from '../../utils';
import { Stat } from '../QuestStats/Stats';
import { ResourceImage } from '../ResourceImage/ResourceImage';

export type SingleQuestType = {
  data?: QuestDetailsType;
  filters?: QuestsFiltersType;
  displayCommunity?: boolean;
  displayTimeAgo?: boolean;
  disabled?: boolean;
  className?: string;
  Icon?: ReactNode;
};

export const SingleQuest = ({
  data,
  filters,
  displayTimeAgo,
  disabled,
  className,
  Icon,
}: SingleQuestType) => {
  const router = useRouter();
  const ref = useRef(null);
  const { t } = useTranslation(['quests']);
  const {
    data: resourceType,
    isError,
    isLoading,
  } = useResource(data?.reward.args?.resourceTypeId);

  async function handleOnClick() {
    if (disabled) {
      return;
    }
    router.query.qid = data?.questId;
    await router.push({ query: router.query }, undefined, {
      scroll: false,
    });
    openModal(MODAL.QUEST_DETAILS, {
      qid: data?.questId,
      filters,
    });
  }

  const rewardAmount = useMemo(() => {
    let amount = data?.reward?.args?.amount;
    return data?.status !== QUEST_STATUS.FAILED ? amount : undefined;
  }, [data?.reward?.args?.amount, data?.status]);

  const hasPrecondition =
    Boolean(data?.parent) &&
    !Boolean(data?.parent?.completedAt) &&
    data?.lockedPrerequisites?.length !== 0;

  const isDiscord = ['assign_role', 'assign_discord_role'].includes(
    data?.reward?.name ?? '',
  );
  const label: string = data?.template.tags?.[0]?.label ?? t('quests:other');

  return (
    <m.div
      initial="rest"
      whileHover="hover"
      animate="rest"
      role="button"
      onClick={handleOnClick}
      className={`single-quest flex middle h-full${!data ? ' empty' : ''}  ${
        className ?? ''
      }`}
      ref={ref}
    >
      <div className="single-quest-shadow" />
      <div className="single-quest-background" />
      {isDiscord ? (
        <div className="quest-reward flex column middle center gap-small">
          <AppIcon.discord size={48} className="discord" />
          <div className="reward-text lexend-body-s font-bold text-ellipsis text-center">
            {data?.reward.args.roleName ?? data?.reward.args.role}
          </div>
        </div>
      ) : null}
      {data?.reward.name === 'credit_resource' ||
      data?.reward.name === 'quest_progress' ? (
        <div className="flex column middle center gap-extraSmall">
          <div className="quest-reward quest-reward--xp ">
            <ResourceImage
              isLoading={isLoading}
              isError={isError}
              resourceType={resourceType}
            />
          </div>

          <div className="reward-text lexend-body-s font-bold text-center">
            {nFormatter(parseInt(rewardAmount ?? '0'), 1)} XP
          </div>
        </div>
      ) : null}
      {data?.reward.name === 'give_assets' ? (
        <div className="quest-reward flex column middle center gap-extraSmall">
          <InventoryItemReward quest={data} showLabel={false} />
          <div className="lexend-body-s font-bold text-center reward-text">
            {resourceType?.name}
          </div>
        </div>
      ) : null}
      <div className="flex column quest-description gap-normal">
        <div className="super flex middle">
          {displayTimeAgo && data && isValid(new Date(data.completedAt)) ? (
            <div className="lexend-body-xs1">
              {formatDistance(new Date(data.completedAt), new Date(), {
                addSuffix: true,
              })}
            </div>
          ) : null}
        </div>
        <div className="lexend-body-l font-bold gap-small flex middle">
          <QuestStatus status={data?.status} />
          <p className="vert-ellipsis quest-title">{data?.title}</p>
        </div>
        <div className="description vert-ellipsis">
          <Parser isPlain content={data?.description} />
        </div>
      </div>

      {!Icon && data && getQuestIcon(data.template.name) ? (
        <div className="quest-icon">
          <Tippy content={label} className="lexend-body-s">
            <Image
              src={getQuestIcon(data.template.name)}
              width={32}
              height={32}
              alt={label}
            />
          </Tippy>
        </div>
      ) : null}
      {Icon ? <div className="quest-icon">{Icon}</div> : null}
      {hasPrecondition && data && (
        <div className="locked flex middle center font-bold">
          <AppIcon.lock size={28} />
          <span className="locked-text">{data.parent?.title}</span>
        </div>
      )}

      {!hasPrecondition && data?.reward.name === QUEST_REWARD_NAMES.PROGRESS ? (
        <RewardProgressOverlay
          resourceType={resourceType}
          isLoading={isLoading}
          isError={isError}
          onClick={handleOnClick}
          quest={data}
        />
      ) : null}
    </m.div>
  );
};

function RewardProgressOverlay({
  onClick,
  quest,
  resourceType,
  isLoading,
  isError,
}: {
  onClick: () => void;
  quest: QuestDetailsType;
  resourceType: ResourceType | undefined;
  isLoading: boolean;
  isError: boolean;
}) {
  const { t } = useTranslation(['quests']);
  const { defaultStatText } = useQuestTitles(quest);
  if (!quest) return null;
  return (
    <m.div
      whileHover={{ opacity: 1, backdropFilter: 'blur(16px)' }}
      className="reward-progress-overlay"
      onClick={onClick}
    >
      <Stat metrics={quest.metrics} defaultStatText={defaultStatText}>
        {({ items }) => (
          <>
            {items.map((item, index) => (
              <div key={index} className="reward-progress-overlay__detail">
                <div className="flex middle center gap-small">
                  <AppIcon.target size={24} color="white" />
                  <p className="lexend-heading-h3 font-bold">{item.value}</p>
                </div>
                <div className="lexend-body-s text-secondary">
                  <p className="stat-title">{item.title}</p>
                </div>
              </div>
            ))}
          </>
        )}
      </Stat>
      <div className="reward-progress-overlay__detail">
        <div className="flex middle center">
          <div className="reward-progress-overlay__resource">
            <ResourceImage
              resourceType={resourceType}
              isLoading={isLoading}
              isError={isError}
            />
          </div>
          <p className="lexend-heading-h3 font-bold">
            {humanize(quest?.resourceEmission ?? 0)}
          </p>
        </div>
        <p className="text-secondary lexend-body-s">
          {t('quests:collected', {
            resourceName: resourceType?.name ?? 'XP',
          })}
        </p>
      </div>
    </m.div>
  );
}
